.container_login {
    background-color: #222424;
    height: 100vh;
    display: flex;
    justify-content: center;
    font-family: "Montserrat";
    position: relative;
    overflow: hidden;
}

.internal_container {
    z-index: 2;
    width: 85%;
    max-width: 1600px;
    /* background-color: rebeccapurple; */
    display: flex;
    flex-direction: column;
}

.title_admin {
    font-size: 18px;
    font-weight: 500;
    color: white;
    text-align: center;
    font-style: italic;
    margin-top: 20px;
    margin-bottom: 20px;
}

.logo_container {
    display: flex;
    align-items: center;
    height: 250px;
    width: 100%;
    gap: 10px;
    justify-content: flex-start;
    color: white;
}

.logo_container h1 {
    font-family: "Peace Sans";
    font-size: clamp(15px, 4.5vw, 21px);
    font-weight: 500;
}

.logo_container p {
    font-family: "Montserrat";
    font-size: clamp(13px, 3.9vw, 18.5px);
    font-weight: 500;
}

.image_logo {
    height: 25%;
}

.container_form {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.div_input {
    width: 100%;
    max-width: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: clamp(10px, 3vw, 50px);
}

.title_input {
    font-size: 18px;
    width: 150px;
    text-align: center;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.div_input input {
    background-color: #222424;
    color: white;
    border: 2px solid rgba(255, 255, 255, 0.413); 
    border-radius: 8px;
    height: 35px;
    width: 100%;
    font-family: "Montserrat";
    padding-left: 10px;
}

.div_input input:focus {
    outline: none;
}


.button {
    font-weight: 600;
    font-size: 20px;
    color: white;
    background-color: transparent;
    border: none;
    transition: all 0.3s ease-in-out;
    padding: 7px;
    border-radius: 7px;
    border: 1px solid white;
}

.button:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: white;
    color: #222424;
}

.faixaRs {
    position: absolute;
    bottom: 0px;
    right: -5px;
    z-index: 1;
    width: 50%;
    max-width: 400px;
}
/* TODO mandar para o email em determinado tamanho de tela como na home */