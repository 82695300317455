/* TODO verificar classes inutilziadas */

.editar_empresa {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1500px;
}

.empresa {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}

.expandida {
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.432);
    transition: all 0.5 ease-in;
    padding: 10px;
}

.div_publicada_abrir {
    height: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
}

.forms {
    width: 100%;
    color: black;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.div_input_text {
    font-size: clamp(14px, 2.5vw, 20px);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input {
    border-radius: 4px;
    width: 100%;
    height: 27px;
    border: 2px solid rgba(0, 0, 0, 0.357);
    padding-left: 5px;
    background-color: transparent;
    font-family: "Montserrat", sans-serif;
}

.input:focus {
    outline: none;
}

.div_bind_images {
    align-self: center;
    display: flex;
    gap: 15px;
    width: 100%;
    max-width: 1600px;
    justify-content: space-evenly;
}

.div_images_button {
    width: 100%;
    max-width: 300px;
}

/* Estilos desativados */

.input:disabled {
    cursor: not-allowed;
}

.input_textarea:disabled {
    cursor: not-allowed;
}

input[name="ajuda"] {
    background-color: rgba(255, 0, 0, 0.556);
}

.desativado {
    transition: all 0.3s ease-in;
    background-color: rgba(255, 0, 0, 0.556);
}


/*   */

.preview {
    width: 100%;
    height: 20vw;
    max-height: 300px;
    /* border: 1px solid rgba(0, 0, 0, 0.65); */
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.432);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.div_buttons_images {
    margin-top: 5px;
    display: flex;
    justify-content: space-evenly;
    gap: 5px;
}

.button_action {
    border: none;
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    transition: all 0.3s ease-out;
    font-weight: 600;
    color: white;
    font-family: "Montserrat", sans-serif;
}

.button_action:hover {
    cursor: pointer;
    transition: all 0.3s ease-in;
}

.delete {
    background-color: rgb(235, 61, 61);
}

.delete:hover {
    color: red;
    background-color: white;
}

.download {
    background-color: rgb(61, 168, 235);
}

.download:hover {
    color: rgb(61, 168, 235);
    background-color: white;
}

.image_plus {
    height: 20%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.sem_imagem {
    user-select: none;
}

.ativado {
    cursor: pointer;
    transition: all 0.3s ease-in;
}

.ativado:hover {
    transform: scale(1.04);
    transition: all 0.3s ease-in;
}


.input_textarea {
    background-color: transparent;
    width: 100%;
    height: 150px;
    max-width: 100%;
    max-height: 150px;
    min-width: 100%;
    min-height: 150px;
    font-family: "Montserrat", sans-serif;
    padding: 5px;
    font-size: 15px;
}

.input_textarea:focus {
    outline: none;
}

.div_input_type_help {
    border: 1px solid rgba(0, 0, 0, 0.628);
    border-radius: 5px;
    padding: 10px;
    max-width: 970px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
}

.div_option {
    display: flex;
    gap: 10px;
    align-items: center;
}

.div_empresa_reduzida {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    font-size: clamp(16px, 2.5vw, 18px);
    font-weight: 400;
    transition: all 0.1s ease;
    cursor: pointer;
}

.data {
    align-self: center;
}

.nome {
    font-size: clamp(14px, 2vw, 18px);
    width: 33%;
}

.icone_abrir {
    max-height: 40%;
    height: 7vw;
}

.publicada {
    max-height: 40%;
    height: 7vw;
}

.empresa_open {
    margin-bottom: 20px;
}

.empresa_close {
    border-bottom: 3px solid rgba(0, 0, 0, 0.327);
}

.empresa_close:hover {
    transition: all 0.1s ease;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.432);
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 50px;
    margin-top: 25px;
}

.div_buttons p {
    font-size: clamp(15px, 2.5vw, 20px);
    font-weight: 500;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    position: relative;
}

.div_buttons:hover {
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
}

@media (max-width: 1110px) {
    .button_action {
        font-size: clamp(5px, 1vw, 10px);
    }
}

@media (max-width: 870px) {
    .div_bind_images {
        flex-direction: column;
        gap: 15px;
        max-height: unset;
    }

    .div_images_button {
        width: 100%;
        max-width: unset;
        max-height: unset;
    }

    .div_buttons_images {
        height: 40px;
    }

    .button_action {
        font-size: 15px;
    }

    .preview {
        height: 100%;
        max-height: unset;
    }

    .sem_imagem {
        width: 100%;
        height: 300px;
    }

    .image_plus {
        object-fit: contain;
        width: 30%;
    }
}

@media (min-width: 1000px) {
    .div_busines_data {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "a1 a2"
            "a3 a4"
            "a5 a6"
            "a7 a7"
            "a8 a8";
    }

    .a1 {
        grid-area: a1;
    }

    .a2 {
        grid-area: a2;
    }

    .a3 {
        grid-area: a3;
    }

    .a4 {
        grid-area: a4;
    }

    .a5 {
        grid-area: a5;
    }

    .a6 {
        grid-area: a6;
    }

    .a7 {
        grid-area: a7;
    }

    .a8 {
        grid-area: a8;
    }

    .div_option {
        width: 40%;
    }

}