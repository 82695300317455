.empresa {
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
    align-self: flex-start;
}

.container_imagem_title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.imagem_empresa {
    width: 80%;
    height: 75vw;
}

.empresa_texts {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}

.empresa_title {
    width: 100%;
}

.nome_empresa {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: clamp(15px, 3.5vw, 20px);
    width: 100%;
}

.desc_empresa {
    width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-size: clamp(13px, 3.5vw, 18px);
}

.empresa_infos {
    border-radius: 10px;
    padding: 5px;
    border: 1px solid #000000;
    display: flex;
    align-items: center;
    font-size: 11px;
    font-weight: 550;
    cursor: pointer;
    transition: all 0.3s ease;
}

.empresa_infos:hover {
    transition: all 0.3s ease-out;
    background-color: rgba(0, 0, 0, 0.219);
}

.icone_ver_mais {
    width: 20px;
    height: 20px;
}

.meios_contato {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 8px;
    max-width: 500px;
}

.meios_contato a {
    color: black;
    transition: all 0.3s ease;
}

.meios_contato a:hover {
    transition: all 0.3s ease;
    color: gray;
}


.icon_text {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    font-weight: 500;
}

.icon_text a {
    width: 80%;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.icon {
    width: 20px;
    height: 20px;
}

.mais_info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 80%;
    max-width: 500px;
    position: relative;
}

.afetada {
    border: 1px solid;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: 500;
    font-size: 12px;
    background-color: #F1EFE4;
    z-index: 11;
    position: absolute;
    max-width: 500px;
    width: 100%;
}

.afetada li {
    list-style: inside;
    margin-left: 15px;
}

@media (min-width: 550px) {
    .imagem_empresa {
        height: 400px;
    }

    .icone_ver_mais {
        width: 15px;
        height: 15px;
    }

    .empresa_infos {
        padding: 5px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
}