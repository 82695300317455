.dropdown_external {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    z-index: 100000000;
}

.containerDropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border: 2px solid black;
    border-radius: 8px;
    font-family: "Montserrat";
    font-weight: 600;
    position: relative;
    background-color: #F1EFE4;
    cursor: pointer;
    transition: all 0.3s ease;
}

.containerDropdown:hover {
    background-color: #00000044;
    transition: all 0.3s ease;
}

.iconeDropdown {
    height: 50%;
}

.filter_options {
    left: 0px;
    margin-top: 10px;
    overflow: auto;
    padding: 10px;
    width: 100%;
    max-height: 300px;
    position: absolute;
    background-color: #F1EFE4;
    border: 2px solid black;
    border-radius: 8px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
}

/* Editar a scrollbar */

.filter_options::-webkit-scrollbar {
    width: 5px;
}

.filter_options::-webkit-scrollbar-thumb {
    background-color: #7a7a7a;
    border-radius: 10px;
}

.filter_options::-webkit-scrollbar-track {
    background-color: #F1EFE4;
    border-radius: 10px;
}

.filter_options::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

@media (max-width: 650px) {
    .dropdown_external {
        position: static;
    }


}


.notClickable {
    pointer-events: none;
}