.sobre_nos {
    background-color: #F1EFE4;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "montserrat";
    width: 100%;
    padding-bottom: clamp(66px, 20vw, 250px);
    padding-bottom: 150px;
    position: relative;
    z-index: 200;
    overflow: hidden;
    position: relative;
}

.internal_sobre_nos {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1600px;
    gap: clamp(10px, 10vw, 100px);
}

.title {
    margin-top: 66px;
    width: 100%;
    font-size: 30px;
}

.text_container {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

.div_texts {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.div_texts h3 {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
}

.div_texts p {
    text-align: justify;
    font-size: clamp(19px, 4vw, 22px);
    font-weight: 500;
}

.separateBar {
    width: 100%;
    max-width: 500px;
    background-color: #FFD700;
}

.band {
    position: absolute;
    z-index: -1;
    max-width: 420px;
    width: 50%;
}

.fst_band {
    top: -5px;
    right: 0px;
    transform: rotate(180deg);
}

.snd_band {
    left: 0px;
    bottom: -5px;
}