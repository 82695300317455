.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.514);
    position: fixed;
    top: 0px;
}

.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttons button:hover {
    opacity: 0.8;
}

.confirma {
    color: white;
    background-color: rgb(43, 197, 43);
}

.recusa {
    color: white;
    background-color: rgb(197, 43, 43);
}