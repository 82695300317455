* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Peace Sans';
    src: url(../fonts/Peace\ Sans\ Webfont.ttf);
}

@font-face {
    font-family: 'Montserrat';
    src: url(../fonts/Montserrat-VariableFont_wght.ttf);
}

@font-face {
    font-family: 'Open Sauce';
    src: url(../fonts/OpenSauceOne-Regular.ttf);
}