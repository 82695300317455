.container {
    height: 100vh;
    width: 100%;
    background-color: #222424;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text {
    font-family: "Montserrat";
    font-size: 45px;
    font-weight: 600;
    color: white;
    text-align: center;
}