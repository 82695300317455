.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.div_carroussel {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.image {
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.buttons {
    border: none;
    background-color: transparent;
    position: absolute;
    width: 30px;
    opacity: 0.5;
    cursor: pointer;
}


.next {
    right: 0;
}

.previous {
    left: 0;
    transform: rotate(180deg);
}