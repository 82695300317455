.container_text_bar {
    border: 4px solid transparent;
    border-bottom: none;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
}

.container_text_bar:hover {
    cursor: pointer;
    transition: all 0.3s ease-out;
}

.text {
    font-weight: 500;
    padding-bottom: 7px;
    color: white;
    font-family: "Montserrat";
    font-size: clamp(11px, 3vw, 18px);
}

.bar {
    display: flex;
    width: 100%;
    /* height: 100%; */
}

.bars {
    display: flex;
    height: 4px;
    width: 33.33%;
}

.yellow {
    background-color: yellow;
}

.red {
    background-color: red;
}

.green {
    background-color: green;
}