nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #222424;
    padding-top: 30px;
    width: 100%;
    padding-bottom: 30px;
    z-index: 1000;
    position: relative;
}

.home_page {
    background-color: transparent;
}


.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 90px;
    max-width: 1600px;
    background-color: transparent;
}

.voluntary_text {
    font-style: italic;
    text-align: center;
    font-size: 10px;
    font-family: "Montserrat";
    color: white;
    font-weight: 500;
    margin-bottom: 30px;
}

.logo_container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 400px;
    justify-content: space-between;
    color: white;
}

.logo_container h1 {
    font-family: "Peace Sans";
    font-size: 21px;
    font-weight: 500;
}

.logo_container p {
    font-family: "Montserrat";
    font-size: 18.5px;
    font-weight: 500;
}

.image_logo {
    height: 100%;
}

.menu {
    display: flex;
    gap: 5%;
    width: 50%;
    justify-content: center;
}

@media (max-width: 1078px) {

    .navbar {
        flex-direction: column;
        height: auto;
        gap: 20px;
    }

    .logo_container {
        width: 100%;
        justify-content: center;
        gap: clamp(3px, 1vw, 10px);
        height: 70px;
    }

    .logo_container h1 {
        font-family: "Peace Sans";
        font-size: clamp(15px, 4.5vw, 21px);
        font-weight: 500;
    }

    .logo_container p {
        font-family: "Montserrat";
        font-size: clamp(13px, 3.9vw, 18.5px);
        font-weight: 500;
    }

    .menu {
        width: 100%;
        justify-content: space-between;
    }

}