.applied_filters {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-family: "Montserrat", sans-serif;
}

.applied_filter {
    display: flex;
    gap: 5px;
    border: 1px solid transparent;
    transition: all 0.3s ease-out;
    padding: 4px;
    font-size: 11px;
    border-radius: 8px;
    font-weight: 500;
    border: 1px solid black;
}