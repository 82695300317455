footer {
    width: 100%;
    background-color: #222424;
    position: relative;
    z-index: 1000;
}

.internal_footer {
    margin: auto;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
}

.logo_container {
    margin-top: 60px;
    display: flex;
    align-items: center;
    height: 90px;
    max-width: 400px;
    width: 99%;
    gap: 10px;
    justify-content: center;
    color: white;
}

.logo_container h1 {
    font-family: "Peace Sans";
    font-size: clamp(16px, 4.5vw, 21px);
    font-weight: 500;
}

.logo_container p {
    font-family: "Montserrat";
    font-size: clamp(14px, 4vw, 18.5px);
    font-weight: 500;
}

.image_logo {
    height: 65%;
}

.container_icon {
    height: auto;
    width: 90%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    font-family: "Open Sauce";
    color: white;
    font-size: clamp(14px, 2vw, 18px);
    gap: clamp(10px, 4vw, 50px);
}

.each_container_icon {
    height: clamp(10px, 4vw, 34px);
    gap: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.each_container_icon a {
    color: white;
}

.social_icons {
    height: 100%;
}

.final_text {
    font-family: "Montserrat";
    color: white;
    font-size: 14px;
    width: 90%;
}

.developed {
    margin-top: 50px;
    width: 100%;
    color: black;
    font-family: "Open Sauce";
    background-color: #F1EFE4;
    font-size: 8px;
    padding: 5px;
    padding-left: 50px;
}

@media (max-width: 484px) {

    .container_icon {
        max-width: 300px;
        justify-content: flex-start;
    }
}