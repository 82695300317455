.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.loading_spinner {
    display: flex;
    justify-content: space-around;
    width: 60px;
    height: 60px;
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: bounce 1.5s infinite;
}

.dot:nth-child(1) {
    background-color: green;
    animation-delay: -0.3s;
}

.dot:nth-child(2) {
    background-color: yellow;
    animation-delay: -0.15s;
}

.dot:nth-child(3) {
    background-color: red;
}

@keyframes bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}