.home {
    width: 100%;
    height: 95vh;
    max-height: 1200px;
    background-image: url('../../images/fundo_home.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 80px;
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.home_internal {
    height: 100%;
    width: 90%;
    max-width: 1600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.container_main_text {
    height: 65%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
}

.main_text {
    font-weight: 550;
    color: white;
    font-size: clamp(22px, 5vw, 30px);
    width: 100%;
    max-width: 800px;
}