.container_option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2px;
    background-color: #F1EFE4;
    z-index: 5;
    transition: all 0.2s ease;
    border-radius: 4px;
    padding: 2px;
    cursor: pointer;
}

.container_option:hover {
    transition: all 0.2s ease;
    background-color: #00000044;
}

.select_option {
    z-index: 5;
    background-color: #F1EFE4;
    min-width: 25px;
    min-height: 20px;
    height: 20px;
    width: 25px;
    border: 2px solid black;
    border-radius: 4px;
}

.selectOption_selected {
    background-color: black;
}

.text_option {
    font-size: 13px;
    font-family: "Montserrat";
}

.no_click {
    pointer-events: none;
}