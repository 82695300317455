.container {
    color: white;
    background-color: #222424;
    display: flex;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    position: relative;
}

.internal_container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 96%;
    gap: 20px;
}

.title {
    font-size: clamp(12.5px, 2.5vw, 20px);
}

.forms {
    width: 100%;
    color: black;
    border-radius: 8px;
    background-color: #F1EFE4;
    padding: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 1000px;
}

.div_input_text {
    font-size: clamp(14px, 2.5vw, 20px);
    font-weight: 500;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.input {
    border-radius: 4px;
    width: 100%;
    height: 27px;
    border: 2px solid rgba(0, 0, 0, 0.357);
    padding-left: 5px;
    background-color: transparent;
}

.input:focus {
    outline: none;
}

.div_bind_images {
    align-self: center;
    display: flex;
    gap: 15px;
    width: 100%;
    max-width: 1600px;
    justify-content: space-evenly;
}

.preview {
    height: 15vw;
    width: 100%;
    max-width: 200px;
    max-height: 200px;
    border: 1px solid rgba(0, 0, 0, 0.65);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_image_button {
    width: 100%;
}

.delete_image {
    color: red;
    font-family: "Montserrat", sans-serif;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 700;
    border-bottom: 1px solid transparent;
    font-size: 10px;
}

.delete_image:hover {
    border-bottom: 1px solid red;
    transition: all 0.3s ease;
}

.sem_imagem {
    cursor: pointer;
    transition: all 0.3s ease;
}

.obs {
    font-size: 11px;
}

.sem_imagem:hover {
    background-color: rgba(0, 0, 0, 0.274);
    transition: all 0.3s ease;
    transform: scale(1.02);
}

.btn {
    background-color: green;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.btn:hover {
    background-color: #24b822;
    transition: all 0.3s ease;
}

.image {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
}

.image_plus {
    height: 20%;
}

.input_textarea {
    background-color: transparent;
    width: 100%;
    height: 150px;
    max-width: 100%;
    max-height: 150px;
    min-width: 100%;
    min-height: 150px;
}

.input_textarea:focus {
    outline: none;
}

.div_input_type_help {
    border: 1px solid rgba(0, 0, 0, 0.628);
    border-radius: 5px;
    padding: 10px;
    max-width: 970px;
    gap: 20px;
    display: flex;
    flex-wrap: wrap;
}

.div_option {
    display: flex;
    gap: 10px;
    align-items: center;
}

.privacy {
    width: 100%;
    text-align: justify;
    font-size: clamp(12px, 2vw, 16px);
    max-width: 1000px;
}

.privacy button {
    font-size: clamp(12px, 2vw, 16px);
    background-color: transparent;
    border: none;
    color: white;
    font-family: "Montserrat";
    cursor: pointer;
    text-decoration: underline;
}

.button_enviar {
    cursor: pointer;
    transition: all 0.3s ease;
}

.button_enviar:hover {
    transform: scale(1.05);
    transition: all 0.3s ease;
}

.container_lgpd {
    height: 100vh;
    top: 0px;
    position: fixed;
    width: 95%;
    background-color: #222424;
    border: 1px solid white;
    padding: 20px;
    z-index: 2000;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.button_entendi {
    text-align: center;
    width: 70px;
    align-self: flex-end;
    font-size: 12px;
    margin-top: 30px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.3s ease;
    border-radius: 5px;
}

.button_entendi:hover {
    transition: all 0.3s ease;
    border: 1px solid white;
}

@media (min-width: 1000px) {
    .div_busines_data {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "a1 a2"
            "a3 a4"
            "a5 a6"
            "a7 a7"
            "a8 a8";
    }

    .a1 {
        grid-area: a1;
    }

    .a2 {
        grid-area: a2;
    }

    .a3 {
        grid-area: a3;
    }

    .a4 {
        grid-area: a4;
    }

    .a5 {
        grid-area: a5;
    }

    .a6 {
        grid-area: a6;
    }

    .a7 {
        grid-area: a7;
    }

    .a8 {
        grid-area: a8;
    }

    .div_option {
        width: 40%;
    }

}

@media (max-width: 485px) {
    .div_input_type_help {
        display: flex;
        flex-direction: column;
    }
}