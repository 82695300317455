.container {
    background-color: #F1EFE4;
    font-family: "Montserrat";
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 50px;
    min-height: 50vh;
}

.container_internal {
    padding-top: 25px;
    width: 99%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    z-index: 200;
}

.title {
    font-family: "Montserrat";
    text-align: center;
    width: 89.5%;
    max-width: 400px;
}

.filter_bar {
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    gap: clamp(10px, 2%, 50px);
    position: relative;
    max-width: 800px;
    width: 90%;
}

.search_companies {
    height: 100%;
    border: 2px solid black;
    border-radius: 8px;
    background-color: transparent;
    transition: all 0.3s ease;
    cursor: pointer;
}

.search_companies:hover {
    transform: scale(1.05);
    background-color: #00000044;
    transition: all 0.3s ease;
}

.icon_search {
    padding: 5px;
    height: 100%;
    transition: all 0.3s ease;
}

.empresas {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 75px;
}

.estilo_externo {
    height: 100%;
    width: 100%;
}

.drop {
    top: 35px;
}

/* Paginação */

.container_paginacao {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin-top: 20px;
}

.paginacao {
    display: flex;
    gap: 2px;
    margin-left: 15px;
    margin-right: 15px;
}

.paginacao_button {
    font-family: "Montserrat";
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 2px;
    font-weight: 600;
}

.selecionado {
    font-weight: 800;
    text-decoration: underline;

}


/* FAIXA */

.faixa {
    position: absolute;
    width: 30%;
    max-width: 200px;
    z-index: 1;
}

.top {
    top: -5px;
    right: 0px;
    transform: rotate(180deg);
}

.bottom {
    left: 0px;
    bottom: -5px;
}

@media (max-width: 800px) {
    .title {
        max-width: unset;
    }
}