.container {
    font-family: "Montserrat";
    padding: 25px;
    background-color: #F1EFE4;
    min-height: 100vh;
    position: relative;
}

.container_internal {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.descricao_pagina {
    font-size: 12px;
    font-style: italic;
    font-weight: 500;
}

.logo_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 100%;
    max-width: 370px;
    gap: clamp(3px, 1vw, 10px);
}

.logo_container h1 {
    font-family: "Peace Sans";
    font-size: clamp(15px, 4.5vw, 21px);
    font-weight: 500;
}

.logo_container p {
    font-family: "Montserrat";
    font-size: clamp(13px, 3.9vw, 18.5px);
    font-weight: 500;
}

.image_logo {
    height: 65%;
}

.filter_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;
    max-width: 1500px;
    height: 35px;
}

.div_filtros {
    max-width: 1500px;
    width: 100%;
    height: 35px;
    display: flex;
    gap: 5px;
    position: relative;
}

.drop {
    top: 30px;
}
.title_excel {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 20px;
}

.estilo_externo {
    height: 100%;
}

.filter_title {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.excel_img {
    height: 90%;
    cursor: pointer;
    transition: all 0.3s ease;
}

.excel_img:hover {
    transform: scale(1.05);
}

.filter_title p {
    padding-left: 10px;
    padding-right: 10px;
    font-size: clamp(17px, 3vw, 20px);
    font-weight: 500;
}

.div_input {
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    gap: 5px;
    max-width: 300px;
}

.input_lupa {
    border: 2px solid rgba(0, 0, 0, 0.574);
    border-radius: 7px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
}

.filter_input {
    font-family: "Montserrat";
    border: none;
    padding-left: 5px;
    background-color: transparent;
}

.filter_input:focus {
    outline: none;
}

.filter_img {
    position: absolute;
    height: 70%;
    right: 5px;
} 

@media (max-width: 650px) {
    .container {
        padding: 15px;

    }
}

@media (max-width: 450px) {

    .div_filtros {
        width: 100%;
        height: auto;
        flex-direction: column;
        position: relative;
    }

    .estilo_externo {
        height: 35px;
    }

    .drop {
        top: 110px;
    }

    .filter_input {
        width: 100%;
    }
}