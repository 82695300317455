.container {
    width: 100%;
    display: flex;
    align-items: center;
    height: 50px;
    justify-content: center;
    gap: 5px;
    list-style: none;
    margin-top: 20px;
}

.li_button_next {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.button_next {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding-left: 5px;
    padding-right: 5px;
    height: 40%;
}

.prev {
    transform: rotate(180deg);
}

.icone_next {
    height: 100%;
}

.button_next:disabled {
    cursor: not-allowed;
    color: #00000067;
}

.page_number {
    font-size: clamp(9px, 1vw, 15px);
    background-color: transparent;
    border-radius: 8px;
    padding: 3px 8px;
    border: none;
    cursor: pointer;
    font-family: "Montserrat";
    font-weight: 500;
    border: 2px solid transparent;
}

.active_page {
    border: 2px solid black;
    font-weight: 700;
}

.page_number:hover {
    transition: all 0.3s ease-in;
    border: 2px solid black;
}